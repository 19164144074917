@import '../../styles/_variables';

.DetailCard {
  position: relative;
  top: -8px;

  .cardBody {
    display: flex;
    flex-direction: row;
  }

  .column {
    margin: 8px 40px 5px 0;

    &:last-child {
      margin-right: 5px;
    }

    .row {
      &.title {
        color: $mediumGray;
        font-size: 12px;
      }

      display: block;
    }
  }
}
