@import '../../styles/variables';

$notificationHeight: 40px;

.AutomatedNotification {
  flex-shrink: 0;
  font-size: 1em;
  background: $notification;
  color: white;
  margin: 0;
  //height: $notificationHeight;
  min-height: $notificationHeight;
  line-height: $notificationHeight;
  font-weight: 400;

  .wrapper {
    display: flex;
    border-left: 40px $blue solid;
    padding-right: 60px;
    width: 100%;

    p {
      margin: 10px 0 10px 15px;
      width: 100%;
    }

    ul {
      margin: 0 0 0 45px;
      padding: 0;

      li {
        padding: 0;
        margin: 10px 0;
        line-height: 1;
      }

      p {
        margin: 0;
      }
    }
  }

  .icon-box {
    margin-left: -40px;
    height: $notificationHeight;
    width: $notificationHeight;
    display: inline-block;
    color: $blue;
    position: relative;

    .icon {
      left: 12px;
      position: absolute;
      margin: 0;
      line-height: $notificationHeight;
      color: white;

      &:first-child {
        color: $blue;
        font-size: 0.6em;
        left: 15px;
        z-index: 1;
      }
    }
  }

  &.info {
    background: $blue;
  }

  &.warn {
    background: $notification;
  }

  &.error {
    background: $red;
  }

  button.link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #fff;

    &:hover,
    &:active {
      color: #fff;
    }
  }

  button.close-btn {
    cursor: pointer;
    outline: none;
    border: none;
    position: absolute;
    right: 15px;
    line-height: $notificationHeight;
  }

  &.info-lite {
    background: lighten($blue, 50%);
    color: $blue;

    .icon-box {
      background: $blue;
    }

    button.link {
      color: $blue;

      &:hover,
      &:active {
        color: $blue;
      }
    }

    button.close-btn {
      color: $blue;
    }
  }
}
