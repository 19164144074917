@import '../../styles/variables';

.BaseEntry {
  display: flex;
  height: 100vh;
  background-image: url('https://cdn.app.corsicatech.com/assets/images/corscia_pattern2_nobackground.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  .vendor-link {
    font-weight: bold;
    color: $primary;
  }

  p {
    margin-bottom: 0;
  }

  .ui.message.recommended-browsers {
    width: 300px;
    margin-top: 2rem;
    background-color: $mediumLightGray4;
    display: flex;
    align-items: center;
    padding: 20px 10px;

    .icon {
      color: $primary;
      font-size: 16px;
      margin-right: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 12px;
    }
  }

  form {
    margin: 3rem auto 0;
    width: 300px;
  }

  .buttonRow {
    margin: 3rem auto 0;
  }

  .viewContainer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    img.brand {
      position: relative;
      margin-left: 12vw;
      margin-bottom: 3rem;

      &.xmas {
        width: 279px;
      }
    }

    .content {
      z-index: 1;
      width: 500px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      margin-left: 12vw;

      > div {
        justify-content: left;
      }
    }
  }
}
