@import '../../styles/_variables';

.patching-widget {
  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .widget-chart-box {
    width: 160px;
    height: 160px;
    position: relative;
    overflow: hidden;
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .widget-chart-legend {
    color: $gray;

    .installed {
      background-color: $blue;
    }

    .pending {
      background-color: $yellow;
    }

    .not-installed {
      background-color: $red;
    }

    .declined {
      background-color: $orange;
    }
  }
}
