@import '../../styles/_variables.scss';

.Card.Malware-widget {
  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .malwareCount {
      font-size: 4rem;
      font-weight: bold;
      line-height: 1;
      color: $orangeMedium;
      height: 55px;
      margin: 10% 0;
    }
  }
}
