@import '../../styles/_variables';

div.Toast {
  width: 350px;
  min-height: 60px;
  background: $info;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(50, 54, 65, 0.3);
  margin-bottom: 1rem;

  div.body {
    padding: 1.5em 1em;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  a.closeButton {
    font-size: 1.75em;
    color: white;
    font-weight: normal;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.25s;

    &:hover {
      transition: background 0.25s;
    }
  }

  &.-info {
    background: $mutedSlate;

    a.closeButton:hover {
      background: darken($mutedSlate, 5);
    }

    a.closeButton:active {
      background: darken($mutedSlate, 8);
    }
  }

  &.-error {
    background: $orange;

    a.closeButton:hover {
      background: darken($orange, 5);
    }

    a.closeButton:active {
      background: darken($orange, 8);
    }
  }

  &.-success {
    background: $success;

    a.closeButton:hover {
      background: darken($success, 5);
    }

    a.closeButton:active {
      background: darken($success, 8);
    }
  }
}
