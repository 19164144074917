@import '../../styles/variables';

.LifeCycleStat {
  .Card {
    height: 100%;

    .cardBody {
      display: flex;
      justify-content: center;
      align-items: center;

      .value {
        font-size: 1.5em;
        color: $gray;
        display: block;

        em {
          font-style: normal;
          font-weight: 600;
          font-size: 1.5em;
        }
      }
    }
  }
}
