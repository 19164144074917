@import '../../styles/variables';

.breadcrumb-container {
  border-top: 1px solid $lightGray;
  border-bottom: 1px solid $lightGray;
  width: 325px;
  margin: 10px auto 30px;
  padding: 5px 0;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    li {
      display: inline-block;
      font-size: 12px;
      color: $lightGray;

      .selected {
        color: $blue;
      }

      &:not(:last-child)::after {
        content: '〉';
        position: relative;
        font-size: 10px;
        line-height: 12px;
        left: 15px;
        color: $mediumLightGray;
      }
    }
  }
}
