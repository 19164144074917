@import '../../styles/variables';

.Card.WidgetDialGaugeGraph {
  .cardBody {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  #svg {
    circle {
      transition: stroke-dashoffset 1s linear;
    }
  }

  .end-caps {
    width: 100%;
    max-width: 375px;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    span {
      display: inline-block;
      width: 45px;
      text-align: center;
      color: $lightText;
      font-size: 16px;
    }
  }

  .center-content {
    position: absolute;
    z-index: 1;
    top: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Emoji img {
      height: 65px;
      width: auto;
      border: 3px solid #eee;
      border-radius: 50%;
    }

    .display-percent {
      font-size: 40px;
      font-weight: bold;
      margin: 20px 0 10px 0;
    }

    small {
      color: $lightText;
    }
  }
}
