@import '../../styles/variables';

.MFAButton {
  display: flex;
  width: 360px;
  margin-top: 15px;
  background: $mediumLightGray3;

  .col-1,
  .col-2 {
    padding: 15px 20px;
  }

  .col-1 {
    padding-right: 0;
  }

  .col-2 {
    width: 100%;

    h4 {
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 7px;

      small {
        display: inline-block;
        padding-left: 3px;
        font-size: 11px;
      }
    }

    p {
      font-size: 12px;
    }
  }

  &.primary {
    background: $primary;
    color: $white;

    .col-2 {
      h4 small,
      p {
        font-weight: 200;
      }
    }
  }

  &.secondary {
    background: $white;
    color: $blue;
    border: 2px solid $blue;

    .col-2 {
      h4 small,
      p {
        font-weight: 200;
      }
    }
  }

  &.disabled {
    background: $mediumLightGray3;
    color: $mediumGray;
    cursor: not-allowed;
  }
}
