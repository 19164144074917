@import '../../styles/_variables';

.CustomToolTipMalware {
  background: white;
  border-radius: $borderRadiusBase;
  box-shadow: $boxShadowTooltip;
  padding: 1rem;

  .row-title {
    width: 80px;
    display: inline-block;
  }

  .orange {
    color: darken($orange, 5);
  }
}
