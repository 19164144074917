@import '../../styles/_variables.scss';

.WidgetIncidentsResolved.Card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  header {
    display: inline-block;
  }

  .cardBody {
    display: flex;
    justify-content: flex-end;
  }

  strong {
    color: $blue;
  }

  .count {
    padding: 15px 0;
    font-size: 45px;
    font-weight: bold;
    align-self: center;
    justify-content: flex-end;
  }
}
