@import '../../styles/_variables';

.ModalUserEdit {
  width: 620px !important;

  .edit-user-actions {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }
}

.EditUserModalView {
  .col-1 {
    width: 40%;
  }

  .col-2 {
    width: 60%;
  }

  .options-box {
    border-top: 2px solid $mediumLightGray3;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .DisplayField {
      width: 47%;

      .FormFieldBase {
        width: 100%;
      }
    }
  }

  .sso-label {
    line-height: 23px;
    height: 23px;

    .checkbox {
      margin-right: 7px;
      top: 4px;
    }
  }
}
