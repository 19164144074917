@import '../../styles/_variables.scss';

.HeaderBanner {
  display: flex;

  .message-area {
    background: $paleRed;
    color: $warnRed;
    padding: 7px 10px;

    .link {
      color: $warnRed;
      text-decoration: underline;
      font-weight: bold;
      display: inline;
    }
  }

  .icon-box {
    background: $warnRed;
    padding: 7px 4px 7px 7px;
  }
}
