@import '../../styles/_variables.scss';

.TabularList {
  width: 100%;
  margin-top: 5px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;

    &:first-child {
      border-bottom: 1px solid $mediumLightGray;
    }

    span {
      cursor: pointer;
      color: $primary;
      font-size: 16px;
    }
  }
}
