@import '../../styles/_variables.scss';

.WidgetIncidents {
  .cardBody {
    display: block !important;
  }

  .count {
    padding: 15px 0;
    font-size: 45px;
    font-weight: bold;
  }

  .percent {
    color: $lightText;
    font-size: 16px;
  }
}
