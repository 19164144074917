@import '../../styles/variables';

$notificationHeight: 40px;

.Notification {
  font-size: 1em;
  background: $notification;
  color: white;
  margin: 0;
  height: $notificationHeight;
  min-height: $notificationHeight;
  line-height: $notificationHeight;
  font-weight: 400;
  padding: 0 20px;

  p {
    margin: 0;
    display: inline-block;
    top: 0;
    left: $notificationHeight + 20;
    position: absolute;
    height: $notificationHeight;
    line-height: $notificationHeight;

    .link {
      color: $blue;
    }
  }

  .icon-box {
    margin-left: -20px;
    height: $notificationHeight;
    width: $notificationHeight;
    display: inline-block;
    color: $blue;
    position: relative;

    .icon {
      left: 12px;
      position: absolute;
      margin: 0;
      line-height: $notificationHeight;
      color: white;

      &:first-child {
        color: $blue;
        font-size: 0.6em;
        left: 15px;
        z-index: 1;
      }
    }
  }

  &.info {
    background: $blue;
  }

  &.warn {
    background: $notification;
  }

  &.error {
    background: $red;
  }

  button.link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #fff;

    &:hover,
    &:active {
      color: #fff;
    }
  }

  button.close-btn {
    cursor: pointer;
    outline: none;
    border: none;
    position: absolute;
    right: 15px;
    line-height: $notificationHeight;
  }

  &.info-lite {
    background: lighten($blue, 50%);
    color: $blue;

    .icon-box {
      background: $blue;
    }

    button.link {
      color: $blue;

      &:hover,
      &:active {
        color: $blue;
      }
    }

    button.close-btn {
      color: $blue;
    }
  }
}
