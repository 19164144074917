@import '../../styles/_variables';

.ConfirmModal {
  .container {
    padding-bottom: 10px;
    color: $black;

    p {
      font-size: 14px;
      padding-bottom: 10px;
    }
  }
}
