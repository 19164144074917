@import '../../styles/_variables.scss';

.Card.email-security-widget {
  & > header {
    width: 70%;
  }

  .emptyStateMessage {
    text-align: center;
    width: 70px;
  }
}
