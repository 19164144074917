/****************************************************
 * Colors
 ***************************************************/

/* NEW */
$newRed: #ce3d3d;
$newRedLight: #e69e9e;

/* NEW */

$mutedSlate: #4d5858;

$disabled: #dadada;
$disabledText: #a1a3a4;
$darkGray: #262c2c;
$mediumDarkGray: #4d5858;
$gray: #5f6972;
$mediumGray: #4d5858;
$mediumMediumGray: #b2b2b2;
$mediumLightGray: #dfdfdf;
$mediumLightGray2: #dee2e5;
$mediumLightGray3: #eee;
$mediumLightGray4: #efefef;
$lightGray: #f5f5f5;
$white: #fff;
$inputBorderGray: #cccfd2;
$faintBorder: $mediumLightGray3;
$faintBorder2: #e0e3ec;

$blue: #2b5597;
$darkBlue: #375d70;
$green: #a0cf69;
$newGreen: #45a041;
$red: #a54450;

$red-2: #d56161;
$red-3: #dd8686;
$red-4: #e5abab;
$red-5: #c0c9c9;

$orange: #ff6b35;
$orangeMedium: #e74a2c;
$yellow: #ffae03;
$yellow-2: #f2ae00;
$teal: #4f7f82;
$fuchsia: #cc1d5f;
$lightOrange: #e77a2c;
$purple: #6c438a;

// HeaderBanner warn colors
$paleRed: #f0dedd;
$warnRed: #ce3e3d;

$notification: #e74a2c;

$loadingText: #7e9090;
$lightText: #7e9090;

// Happy friendly colors

$primary: $blue;
$secondary: $mutedSlate;
$accent: $orange;

$destructive: $orangeMedium;
$success: $green;
$danger: $orange;
$info: $blue;

$black: #262c2c;

$textColor: $black;

// one-offs
$sidebarText: #979797;
$sidebarBackgroundActive: #1a2020;
$sidebarBackgroundHover: #1d2222;

$tabBackground: #ececec;
$tabText: #9b9faa;
$tabSelectedBackground: #fff;
$tabSelectedText: $black;

$faintError: #fff6f6;

// Color classes
.-bg-primary {
  background: $primary;
}

.-bg-secondary {
  background: $secondary;
}

.-bg-accent {
  background: $accent;
}

.-bg-success {
  background: $success;
}

.-bg-danger {
  background: $danger;
}

.-bg-info {
  background: $info;
}

.-bg-darkGray {
  background: $darkGray;
}

.-bg-gray {
  background: $lightGray;
}

.-text-primary {
  color: $primary;
}

.-text-secondary {
  color: $secondary;
}

.-text-accent {
  color: $accent;
}

.-text-success {
  color: $success;
}

.-text-danger {
  color: $danger;
}

.-text-info {
  color: $info;
}

.-text-light {
  color: white;
}

.text-dark {
  color: $darkGray;
}

/****************************************************
 * Misc.
 ***************************************************/
$borderRadiusBase: 3px;
$boxShadowSoft: 0 4px 8px 0 rgba(55, 93, 112, 0.1);
$boxShadowBigly: -8px 0 18px 0 rgba(0, 0, 0, 0.3);
$boxShadowTooltip: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

/****************************************************
 * Typography
 ****************************************************/
$baseFontSize: 16px;
$bodyFontFamily: 'Open Sans', sans-serif;

$flipTransition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
