@import '../../styles/_variables';

.ui.popup.TableRowOptionPopup {
  margin: 0;
  padding: 0;
  left: 10px !important;

  .EditMenu {
    list-style: none;
    margin: 0;
    padding: 0;

    p {
      cursor: pointer;
      padding: 10px 10px;
      width: 120px;
      margin: 0;
      transition: color, background-color 0.3s;

      &:hover {
        background: $lightGray;
      }
    }
  }
}

.ui.circular.button.ghost {
  transition-duration: 0.3s;
  color: $mediumGray;
  font-size: 16px;
}

.ui.circular.button.ghost:hover {
  background-color: $lightGray;
}

div.Card .cardBody .ui.active.loader {
  margin: 0;
}

.user-loader .ui.loader {
  left: 75% !important;
}
