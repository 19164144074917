@import '../../styles/_variables';

.CountDown {
  height: 10px;
  background: #ccc;
  position: relative;
  overflow: hidden;

  .SandsOfTime {
    height: 10px;
    width: 100%;
    background: $primary;
    animation-duration: 60s;
    animation-name: countdown;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes countdown {
    from {
      width: 100%;
    }

    to {
      width: 0%;
    }
  }
}
