.DetailRowItem {
  width: 100%;

  span {
    display: inline-block;
    font-size: 14px;
  }

  .title {
    margin-right: 0.3rem;
    font-weight: bold;
  }
}
