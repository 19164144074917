@import '../../styles/_variables';

.ChatBot {
  font-family: $bodyFontFamily;

  .ac-textBlock {
    font-size: 12px !important;
  }

  .markdown {
    a {
      font-weight: 600;
      color: $blue;
      text-decoration: underline;
    }
  }

  .ac-actionSet {
    button.ac-pushButton {
      border-color: #e2e2e2 !important;
      font-weight: 500;
    }
  }
}
