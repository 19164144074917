@import '../../styles/variables';

.CSATFeedback {
  border-top: 5px solid $blue;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: none;

  .quote-marks {
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: 1;
  }

  .cardBody {
    display: block !important;
  }

  .feedback-container {
    margin-bottom: 20px;
  }

  .soft {
    color: $lightText;
  }

  .csat-icon-options {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .csat-comment {
    width: 100%;
    resize: none;
    margin: 20px 0;
  }

  .csat-consent-checkbox {
    padding: 0 0 1em 0;
  }

  .controls {
    width: 100%;
    margin-bottom: 20px;
  }

  .static-comment {
    display: flex;
    align-items: center;

    p {
      margin-left: 20px;
    }

    padding-bottom: 20px;
  }

  .change-feedback {
    width: 100%;
    text-align: center;
    padding: 20px 0;

    button {
      color: $primary;
    }
  }
}
