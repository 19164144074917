@import '../../styles/variables';

.NotificationContentRow {
  font-size: 1.1rem;
  background: $notification;
  color: white;
  margin: 0;
  height: 60px;
  min-height: 60px;
  line-height: 60px;
  font-weight: 400;
  padding: 0 20px;

  &.info {
    background: $blue;
  }

  &.warn {
    background: $notification;
  }

  &.error {
    background: $red;
  }

  button.link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #fff;

    &:hover,
    &:active {
      color: #fff;
    }
  }

  button.close-btn {
    cursor: pointer;
    outline: none;
    border: none;
    position: absolute;
    right: 15px;
    top: 20px;
    font-size: 1.3rem;
  }
}
