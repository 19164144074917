@import '../../styles/_variables';

.internet-utilization-widget {
  .interface-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  .Interface {
    border-left: 0;

    div.Card {
      header {
        padding-left: 0;
        padding-right: 0;
        word-break: break-all;
      }

      .cardBody {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        .percent {
          color: $gray;
        }
      }
    }
  }
}
