@import '../../styles/variables.scss';

.ProgressBar {
  &.clickable {
    cursor: pointer;
  }
  .labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .ui.progress .bar {
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0;
  }

  .ui.tiny.progress {
    margin: 0;
    .bar {
      height: 8px;
      min-width: 0;
    }
  }

  /* map of classNames to associate with their colors */
  $color-list: (
    'blue': $blue,
    'orange': $yellow-2,
    'red': $warnRed,
    'red-2': $red-2,
    'red-3': $red-3,
    'red-4': $red-4,
    'red-5': $red-5,
  );

  /* stylelint-disable-next-line at-rule-no-unknown -- StyleLint doesn't know about this cool @each stuff */
  @each $list-name, $list-color in $color-list {
    &.#{$list-name} {
      .bar {
        background-color: $list-color;
      }
    }
  }
}
