@import '../../styles/variables';

.warningContainer {
  display: flex;
  height: 100vh;
  background-image: url('https://cdn.app.corsicatech.com/assets/images/corscia_pattern2_nobackground.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  p {
    margin-bottom: 0;
  }

  .ui.message {
    width: 500px;
    margin-top: 1rem;
    padding: 20px;

    p {
      padding: 0;
      font-weight: normal;
      font-size: 14px;
    }
  }

  form {
    margin: 3rem auto 0;
    width: 300px;
  }

  .buttonRow {
    margin: 3rem auto 0;
  }

  .viewContainer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    img.brand {
      position: relative;
      margin-left: 12vw;
      margin-bottom: 3rem;

      &.xmas {
        width: 279px;
      }
    }

    .content {
      width: 500px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      margin-left: 12vw;

      > div {
        justify-content: left;
      }
    }
  }

  .headline {
    font-size: 22px;
    font-weight: bold;
    color: #4d5858;
    margin-bottom: 35px;
  }
}
