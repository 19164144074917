@import '../styles/_variables.scss';

.CustomerSelect {
  height: 36px;
  display: flex;
  align-content: center;

  ::placeholder {
    font-weight: 600;
    color: $black;
  }

  .parent-selector {
    width: 350px;
  }

  .child-selector {
    width: 200px;
  }

  .customerName {
    color: $black;
    font-weight: 600;
    font-size: 16px;
    align-self: center;
    margin-right: 20px;

    .tier,
    .region {
      font-weight: normal;
      font-size: 12px;
      color: $mediumMediumGray;
    }

    .tier {
      text-transform: uppercase;
    }
  }

  .contractTier {
    font-size: 12px;
    margin-left: 5px;
    text-transform: uppercase;
    color: darken($blue, 5);
    border-color: $blue;

    &.elite {
      color: darken($yellow, 5);
      border-color: $yellow;
    }

    &.essentials {
      color: darken($mutedSlate, 5);
      border-color: $mutedSlate;
    }
  }

  .FormFieldBase {
    margin-right: 20px;
  }
}

.ui.button.btn-imitate-client {
  height: 34px;
}
