@import '../../styles/_variables.scss';

.WidgetRequests {
  .cardBody {
    display: block !important;
  }

  .count {
    padding: 15px 0;
    font-size: 45px;
    font-weight: bold;
  }

  .percent {
    color: $lightText;
    font-size: 16px;
  }

  .tabular-list {
    width: 100%;
    margin-top: 5px;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 0;

      &:first-child {
        border-bottom: 1px solid $mediumLightGray;
      }

      span {
        cursor: pointer;
        color: $primary;
        font-size: 16px;
      }
    }
  }
}
