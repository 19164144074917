@import '../../styles/_variables';

nav.SectionNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 auto;
  font-size: 1.15em;
  margin-bottom: 2rem;
  border-bottom: 2px solid $mediumLightGray;

  a,
  .tab-link {
    display: flex;
    color: $mutedSlate;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    height: 50px;
    padding: 0 2rem;
    transition: all 0.25s;
    margin-bottom: -2px;

    &.active {
      color: $primary;
      transition: all 0.25s;
      border-bottom: 2px solid $primary;

      &:hover {
        color: $primary;
      }
    }

    &:hover {
      cursor: pointer;
      color: $darkGray;
      transition: all 0.2s;
    }
  }
}
