@import '../../styles/variables';

.WidgetPhishTestingOverview {
  .wrapper {
    width: 100%;
  }

  .chartDisclaimer {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: $mediumGray;
    font-size: 0.75em;
  }

  .legend-wrapper {
    font-size: 13px;
    margin-bottom: 15px;

    > span:first-child {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .Dot {
    height: 9px;
    width: 9px;
    margin-right: 5px;
  }
}
