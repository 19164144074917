@import '../../styles/_variables.scss';

.DevTools {
  position: fixed;
  bottom: 15px;
  left: 25px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  width: 220px;
  background: $orange;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  z-index: 1000;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.5s;

  > span {
    position: absolute;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    //margin-right: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .FormTextArea {
    width: 100%;
  }

  &.hidden {
    display: none;
  }

  &.shrunkDevTools {
    width: 40px;
  }
}

.DevToolsModal {
  min-height: 400px;
  padding: 20px;
}

.Features {
  ul {
    border: 1px solid #eee;
    padding: 5px 15px;
    width: 100%;
    margin: 10px 0;
    list-style-type: none;
    max-height: 400px;
    overflow: scroll;
  }

  li {
    width: 100%;
  }
}
