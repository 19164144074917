@import '../../styles/_variables';

.StatusBanner {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;

  /* map of classNames to associate with their colors */
  $color-list: (
    'blue': $primary,
    'yellow': $yellow,
    'green': $green,
    'red': $warnRed,
    'orange': $orange,
    'fuchsia': $fuchsia,
    'purple': $purple,
  );

  /* stylelint-disable-next-line at-rule-no-unknown -- StyleLint doesn't know about this cool @each stuff */
  @each $list-name, $list-color in $color-list {
    &.#{$list-name} {
      background: $list-color;
      color: #fff;

      span {
        background: lighten($list-color, 40%);
        color: $list-color;
      }
    }
  }

  .icon {
    width: 50px;
    font-size: 16px;
    align-self: center;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
  }

  span {
    display: inline-block;
    padding: 10px;
  }
}
