@import '../../styles/variables';
/* stylelint-disable no-descending-specificity */
// Outer container
div.Card.Interfaces {
  .cardBody {
    padding: 0;
    display: block;
  }
}

// Inner elements
.Interface {
  border-left: 10px solid transparent;
  padding-bottom: 5px;

  .ui.progress .bar {
    min-width: 0% !important;
  }

  &.withHover {
    cursor: pointer;
  }

  & > .Card {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-shadow: none;
  }

  &.blue {
    border-color: $blue;

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $blue;
      background: $blue;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  &.fuchsia,
  &.red {
    border-color: $fuchsia;

    .bar {
      background-color: $fuchsia !important;
    }

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $fuchsia;
      background: $fuchsia;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  &.green {
    border-color: $green;

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $green;
      background: $green;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  &.orange {
    border-color: $orange;

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $orange;
      background: $orange;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  &.black {
    border-color: $black;

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $black;
      background: $black;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  &.teal {
    border-color: $teal;

    .bar {
      background-color: $teal !important;
    }

    &.withHover:hover,
    &.withHover:hover * {
      color: #fff !important;
      border-color: $teal;
      background: $teal;

      .bar {
        background-color: #fff !important;
      }
    }
  }

  div.Card {
    border-top: 1px solid $lightGray;

    header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 11px;
    }

    .cardBody {
      padding: 0 20px;

      .progress {
        display: flex;
        align-items: center;
        line-height: 20px;

        .percent {
          padding-right: 8px;
          font-size: 11px;
          display: inline-block;
          width: 38px;
          text-align: right;
        }

        i {
          position: relative;
          font-size: 9px;
          color: $gray;
          line-height: 8px;
        }

        .progressBar {
          flex-grow: 5;
          margin: 0;
        }
      }
    }
  }
}
