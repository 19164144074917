@import '../../styles/_variables.scss';

.Card.refreshes-widget {
  .utilization-table {
    width: 100%;
    position: relative;
    font-size: 8pt;
    color: $disabledText;

    .table-heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $mediumGray;
      padding-bottom: 4px;
      font-size: 0.85rem;
      font-weight: 500;
      color: $mediumGray;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      border-bottom: 1px solid $faintBorder;

      a {
        padding: 10px 2px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        color: inherit;

        span {
          font-weight: bold;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
