@import '../../styles/variables';

.WidgetTicketSatisfactionRating {
  .null-message {
    padding-top: 40px;
    font-size: 16px;
    color: $gray;

    button {
      margin-top: 8px;
    }
  }
}
