.Emoji {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.can-modify {
    cursor: pointer;
  }

  img {
    height: 40px;
    width: 40px;
  }

  span {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
