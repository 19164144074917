@import '../../styles/_variables.scss';

.Feedback {
  position: fixed;
  bottom: -45px;
  right: 25px;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  width: 190px;
  background: $fuchsia;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  z-index: 1000;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.5s;

  > span {
    position: absolute;
    margin-left: 30px;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    margin-right: 10px;
    position: absolute;
  }

  .FormTextArea {
    width: 100%;
  }
}

.Feedback.hidden {
  display: none;
}

.ContentEntry {
  h3 {
    padding-top: 20px;
  }

  p {
    font-size: 14px;
  }

  .FormTextArea {
    margin-top: 10px;
  }

  .button-box {
    margin: 10px 0 20px;
  }
}

.ContentSubmitted {
  text-align: center;

  .Success {
    margin-top: 30px;

    .checkmark {
      margin: 0 auto 14px auto;
    }
  }

  h2 {
    font-size: 26px;
  }

  p {
    font-size: 14px;
    color: $gray;
    width: 80%;
    margin: 10px auto 40px;
    line-height: 1.5;
  }

  .ui.basic.button {
    color: $blue !important;
  }
}

.shrunkFeedback {
  width: 40px;
  // > span {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   opacity: 0;
  // }
  > .icon {
    top: 9px;
    left: 12px;
  }
}
