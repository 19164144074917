@import '../../styles/_variables';

.ScheduleHelpButton {
  .content {
    padding: 0 !important;
  }

  iframe {
    border: none;
    height: 80vh;
    width: 500px;
  }
}
