@import '../../styles/_variables.scss';

.AddTicketModal.ui.modal {
  .FormSelect .component input::placeholder {
    color: $black !important;
    opacity: 1 !important;
  }

  max-width: 600px;

  h2 {
    color: $mediumGray;
    margin-bottom: 8px;
  }

  .subheading {
    color: lighten($mediumGray, 10%);
    margin: 0;
    padding: 0;
    font-weight: 200;

    strong {
      font-weight: bold;
      color: $mediumGray;
    }
  }

  .subject-length-warning {
    color: $lightText;
    position: relative;
    font-size: 12px;
    top: -12px;
  }

  .Success {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & > .button {
      margin-left: 0;
      margin-right: 0.75em;
    }
  }

  .submitted {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      text-align: left;
      width: 100%;

      .simulated-link {
        cursor: pointer;
        text-decoration: underline;
        color: $primary;
        font-weight: bold;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: normal;
      text-align: center;
    }
  }

  .form-row {
    display: flex;
    justify-content: space-between;

    .add-margins {
      margin: 0 20px;
    }
  }

  .content {
    p {
      font-size: 14px;
    }
  }
}
