@import '../../styles/_variables';

$sidebarTextOffset: 28px;

section.sidebar {
  flex: 0 0 220px;
  padding: 1rem 0;
  background: $darkGray;
  overflow: auto;

  .brand {
    z-index: 1;
    position: relative;

    img {
      margin-left: $sidebarTextOffset;
      width: 153px;
      height: 67px;
      display: inline-block;
    }

    .main {
      margin-top: 2rem;

      a {
        padding: 0.5rem 1.2rem;
        font-size: 12px;
        display: block;
        text-align: left;
        color: $sidebarText;
        transition: all 0.23s ease-in-out;
        outline: none;
        position: relative;
        white-space: nowrap;

        .Pill {
          font-size: 10px;
          line-height: 14px;
          padding: 0 6px;
          height: 14px;
          background: $newGreen;
          letter-spacing: 1px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: $newGreen;
          transform: translateX(-4px);
          transition: all 0.3s ease-in-out;
        }

        &.active {
          background-color: $sidebarBackgroundActive;
          font-weight: 500;
          color: white;

          &::before {
            transform: translateX(0);
          }
        }

        &:hover {
          background-color: $sidebarBackgroundHover;
        }
      }

      section {
        border-top: 2px solid $sidebarBackgroundHover;
        margin-top: 0.5rem;
        padding-top: 1rem;

        label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: white;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 12px;
          text-align: left;
          padding: 0.5rem 1.2rem;
          font-weight: 400;
          letter-spacing: 1.5px;
          cursor: pointer;
        }

        .icon {
          color: $mediumGray;
        }

        &.expanded {
          .icon {
            color: $lightGray;
          }
        }
      }
    }
  }
}

.EnvBadge {
  text-align: left;

  span {
    margin-left: $sidebarTextOffset;
    margin-top: 10px;
    display: inline-block;
    padding: 2px 8px;
    font-size: 12px;
  }

  &.development span,
  &.e2eTesting span,
  &.testing span {
    background: #de2438;
    color: #fff;
  }

  &.staging span {
    background: #f2ae00;
    color: #262c2c;
  }

  &.demo span {
    background: #50aa42;
    color: #262c2c;
  }

  &.production {
    /* just to be sure */
    display: none;
  }
}
