@import '../../styles/_variables.scss';

.Card.dashboard-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cardBody {
    display: flex;
    justify-content: center;

    .shortDashWidget {
      width: 250px;

      h2 {
        margin: 0;
        font-size: 16px;
      }

      > .number {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1;
        color: $primary;
        text-align: right;

        &.red {
          color: $orangeMedium;
        }
      }

      .addedCount {
        font-size: 1rem;
        color: $mediumGray;
        text-align: right;
      }
    }
  }
}

.widget-loader-right {
  header {
    width: 50%;
    position: absolute;
  }

  .ui.loader::before,
  .ui.loader::after {
    left: 80%;
  }

  .emptyStateMessage {
    margin: 2rem 0 2rem auto !important;
    width: 120px;
  }
}
