@import '../../styles/_variables.scss';

.Card.WidgetClientTeam {
  border-top: 3px solid $primary;
  color: $black;

  header {
    padding: 15px 20px;
  }

  .cardBody {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }

  .ContactBlock {
    margin-bottom: 15px;
    padding-left: 20px;

    .name {
      font-weight: bold;
      font-size: 15px;
    }
    .title {
      color: $disabledText;
    }
    .title,
    .email,
    .phone {
      font-size: 12px;
      line-height: 15px;
    }
  }
}
