@import '../../styles/_variables';

div.SectionHeader {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  padding: 1rem 1.5rem;
  background: $lightGray;
  width: 100%;
  z-index: 1000;
  align-items: center;
  box-shadow: none;
  height: 78px;

  &.wrappedHeaderWithBackBtn {
    display: flex;
    align-items: center;

    .backButton {
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      line-height: 30px;
      text-align: center;
      color: $primary;
      transition: background 0.25s;

      &:hover {
        cursor: pointer;
        background: rgba($primary, 0.15);
        transition: background 0.25s;
      }
    }

    i.icon.back-btn-icon {
      display: static;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      margin: 0;
    }
  }

  &.stuck {
    position: fixed;
    top: 60px;
    left: 220px;
    padding: 1rem 1.5rem;
    box-shadow: $boxShadowSoft;
    transition: box-shadow 0.25s;
    // subtract sidebar width since switching to fixed positioning
    width: calc(100% - 220px);
    z-index: 10;
  }

  h1 {
    margin: 0;
    flex: 0 0 auto;
    display: block;

    small {
      display: block;
      font-size: 14px;
      line-height: 1.2;
      font-style: italic;
      font-weight: normal;
      color: $mediumGray;

      &.non-italic {
        font-style: normal;
        position: relative;
        height: 16px;
        width: 250px;
      }
    }
  }

  div.headerActions {
    display: flex;
    flex: 1 1 auto;
    margin-left: 1rem;
    justify-content: flex-end;
    align-items: center;

    input {
      margin-bottom: 0;
    }
  }
}

.hasNotification div.SectionHeader.stuck {
  top: 120px;
}

div.SectionHeader.hasLocalNotification {
  top: 70px;
}

.notification {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
  width: 100%;
  z-index: 999;
  height: 50px;

  h4 {
    background: $primary;
    color: #fff;
    padding: 10px 0;
    font-weight: normal;
    font-size: 14px;

    .icon {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &.info h4 {
    background: $primary;
    color: #fff;
  }

  &.error h4 {
    background: $orange;
    color: #fff;
  }
}
