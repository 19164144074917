@import '../../styles/_variables.scss';

.WidgetTicketCategories {
  width: 100%;

  .Card .cardBody {
    justify-content: space-between;
  }

  .center-legend span {
    color: $black !important;
    font-weight: 600 !important;

    small {
      color: $lightText;
      font-weight: normal;
      white-space: nowrap;
    }
  }
}
