@import '../../styles/_variables.scss';

.Card.WidgetProgressRows {
  header {
    padding-bottom: 0;
  }

  .cardBody {
    justify-content: space-between;
  }

  .main-section {
    display: flex;
    flex-direction: column;
    align-self: left;
    justify-content: center;

    &.clickable {
      cursor: pointer;
    }

    span {
      height: 45px;
      line-height: 45px;
      font-size: 45px;
      font-weight: bold;
      display: inline-block;

      &.red {
        color: $warnRed;
      }
    }
    h2 {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 450;
      color: $black;
      small {
        font-size: 14px;
        font-weight: 200;
        color: $gray;
      }
    }
  }

  .progress-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
  }
}
